import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo"; 
import Navbar from "../components/_App/Navbar";
import Footer from "../components/_App/Footer";
import PageBanner from '../components/Common/PageBanner'; 
import ContactInfo from '../components/Contact/ContactInfo';
import GoogleMap from '../components/Contact/GoogleMap';
import ContactForm from '../components/Contact/ContactForm';

const Contact = () => (
    <Layout>
        <SEO title="Contact" />

        <Navbar />

        <div className="page-title-area">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">


                        <h2 style={{margin:'30px'}} >ÜRÜNLER</h2>
        <ContactInfo />
        </div>
                </div>
            </div>
        </div>

        <Footer /> 
    </Layout>
)

export default Contact;