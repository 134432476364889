import React from 'react';
import * as Icon from 'react-feather';

const ContactInfo = () => {
    return (
        <div className="contact-info-area ptb-80">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-6 col-sm-6">
                        <div className="contact-info-box">
                            <div className="icon">
                                <Icon.Mail />
                            </div>
                            <h3>Bizimle Whatsapp üzerinden iletişime geçebilirsiniz.</h3>
                            <p><a href="https://wa.me/903326062054">Whatsappdan Canlı Destek Hattı İçin Buraya Tıklayınız</a></p>
                            <p></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactInfo;  